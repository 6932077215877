import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

export default function GetSupport(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <section className={`   ${styles.description}`}>
            <h2>Get the Support You Need</h2>
            <p className="font-light">
                The water contamination at Camp Lejeune adversely affected the
                health of over a million Marines, Sailors, civilian workers, and
                their families.
            </p>
            <p className="font-light">
                If you have been affected, you can receive fair compensation.
                Contact us today to schedule a free legal consultation.
            </p>
            <button
                onClick={() => scrollToTop()}
                style={{ backgroundColor: "#1B2F48", color: "white" }}
                className="lg:px-9  px-4 py-4  rounded-lg hover:opacity-95 font-semibold lg:text-lg text-base mx-auto block mt-10"
            >
                Get My Free Lawsuit Case Review
            </button>
        </section>
    );
}
